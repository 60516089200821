.grid {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    // flex-direction: column;
    grid-gap: 10px;
  }
  
.grid-row {
display: flex;
flex-direction: column;
justify-content: center;
}

.grid-item {
background-color: lightgray;
padding: 20px;
text-align: center;
}

.grid-container {
  display: flex;
  justify-content: center;
  // align-items: center;
  // height: 100vh;
}